/**
 * This route is dynamically added to the app if this section is
 * enabled inside the config for the tenant being built.
 */
export function acpBankTransfersStates($stateProvider) {
  'ngInject';

  $stateProvider.state('bank-transfers', {
    parent: 'root',
    url: '/bank-transfers?oauth_state_id',
    template: `<acp-bank-transfers></acp-bank-transfers>`,
    data: {},
    resolve: {
      module($ocLazyLoad) {
        'ngInject';

        return import(/* webpackChunkName: "acp.bank.transfers" */ './index').then(
          $ocLazyLoad.loadModule
        );
      }
    }
  });
}
